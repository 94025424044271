import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import router from './modules/router'
import user from './modules/user'
import payment from './modules/payment'

Vue.use(Vuex)
Vue.config.devtools = true

// // 这里定义一个独立的防抖函数
// export function debounce(func, wait) {
//   let timeout;
//   return function(...args) {
//     if (typeof func !== 'function') {
//       throw new TypeError('Expected a function');
//     }
//     clearTimeout(timeout);
//     timeout = setTimeout(() => func.apply(this, args), wait);
//   };
// }

const store = new Vuex.Store({

  modules: {
    router,
    user,
    payment
  },

  state: {
    isLogin: false,
    deviceType: '',
    prePath: '',
  },
  mutations: {

    // 访问起始时即向vuex写入设备类型
    SET_DEVICE_TYPE(state, deviceType){
      state.deviceType = deviceType
    },

    // 登录时将token写入全局state，并置isLogin为true
    SET_LOGIN(state){
      state.isLogin = true
    },

    // 退出时将token从全局state中抹除，并置isLogin为false
    SET_LOGOUT(state){
      state.isLogin = false
    },

    SET_PRE_PATH(state, prePath) {
      state.prePath = prePath
    },

    CLEAR_PRE_PATH(state) {
      state.prePath = ''
    },
  },

  actions: {
    // debounce({ commit }, func, wait) {
    //   return debounce(func, wait);
    // }
  },

  getters
})

export default store
