import pcRoutes from "@/router/modules/pc/initRoutes"
import mbRoutes from "@/router/modules/mobile/initRoutes"
import isMobile from "@/utils/device"
import isWeixin from "@/utils/device"

let initialRoutes = []
if(isMobile()) {
  initialRoutes = mbRoutes
} else {
  initialRoutes = pcRoutes
}

const state = {
  initRoutes: [],
  addedRoutes: [],
  allRoutes: []
}

const mutations = {

  SET_INIT_ROUTES: (state) => {
    state.initRoutes = initialRoutes
  },

  SET_ROUTES: (state, routes) => {
    state.addedRoutes = routes
    state.initRoutes = initialRoutes
    state.allRoutes = state.initRoutes.concat(routes)
  },

  CLEAR_ROUTES: (state) => {
    state.initRoutes = []
    state.addedRoutes = []
    state.allRoutes = []
  }
}

const actions = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
