const state = {
    amount: '',
    code_url: '',
    demand_id: ''
}

const mutations = {
    SET_CODEURL: (state, code_url) => {
        state.code_url = code_url
    },
    SET_AMOUNT: (state, amount) => {
        state.amount = amount
    },
    SET_DEMANDID: (state, demand_id) => {
        state.demand_id = demand_id
    }
}

export default {
    namespaced: true,
    state,
    mutations
  }