import request from "@/network/utils/request"

export function checkUserRoleRequest(data){
  return request({
    url: 'user/check-user-role/',
    method: 'get',
    params: data
  })
}

export function checkAccessDemandDetails(data){
  return request({
    url: 'user/check-access-demand-details/',
    method: 'get',
    params: data
  })
}

export function userModifyUsername(data){
  return request({
    url: 'user/modify-username/',
    method: 'post',
    data
  })
}

export function userModifyUserPassword(data){
  return request({
    url: 'user/modify-user-password/',
    method: 'post',
    data
  })
}

export function userResetUserPassword(data){
  return request({
    url: 'user/reset-user-password/',
    method: 'post',
    data
  })
}

export function userQueryUser(){
  return request({
    url: 'user/query-user/',
    method: 'get',
  })
}

export function userWxLoginBindPhone(data){
  return request({
    url: 'user/wx-login-bind-phone/',
    method: 'post',
    data
  })
}

// 微信手机绑定
export function userPhoneLoginBindWx(data){
  return request({
    url: 'user/phone-login-bind-wx/',
    method: 'post',
    data
  })
}

// 拿取二维码
export function userWxRequestQrcode(){
  return request({
    url: 'user/wx-request-qrcode/',
    method: 'get',
  })
}
// 按用户id获取用户名
export function FetchUsername(data){
  return request({
    url: 'user/fetch-username/',
    method: 'get',
    params: data
  })
}

// 轮询函数
export function userPollingResponse(data){
  return request({
    url: 'user/polling-response/',
    method: 'get',
    params: data
  })
}

// 公众号登录
export function userWxFwhLogin(data){
  return request({
    url: 'user/wx-fwh-login/',
    method: 'post',
    data
  })
}

// pc绑定微信
export function userPhoneLoginBindWxPc(data){
  return request({
    url: 'user/phone-login-bind-wx-pc/',
    method: 'post',
    data
  })
}

// 解绑微信
export function userUnbindWx(data){
  return request({
    url: 'user/unbind-wx/',
    method: 'post',
    data
  })
}

// 手机绑定微信
export function userPhoneLoginBindWxMobile(data){
  return request({
    url: 'user/phone-login-bind-wx-mobile/',
    method: 'post',
    data
  })
}
