export function loginLocalStorage(userInfo){
  localStorage.setItem("userInfo", JSON.stringify(userInfo));
}

export function loginSessionStorage(userInfo){
  sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
}

export function routesLocalStorage(routes){
  localStorage.routes = routes;
}

export function routesSessionStorage(routes){
  sessionStorage.routes = routes;
}


