import Vue from "vue"
import Router from "vue-router"
import axios from "axios"
import VueAxios from "vue-axios"
import pcRoutes from "@/router/modules/pc/initRoutes"
import mbRoutes from "@/router/modules/mobile/initRoutes"
import isMobile from "@/utils/device"
import isWeiXin from "@/utils/device"

Vue.use(VueAxios, axios)
Vue.use(Router)

let initialRoutes = []
if(isMobile() || isWeiXin()) {
  initialRoutes = mbRoutes
} else {
  initialRoutes = pcRoutes
}

const createRouter = () => new Router({
  mode: 'history', // require service support
  routes: initialRoutes
})
const router = createRouter()


router.selfAddRoutes = function (params){
  router.matcher = new Router({
    linkActiveClass: "active",
    routes: initialRoutes
  }).matcher;
  router.addRoutes(params)
}

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
