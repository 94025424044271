const getters = {
  permission_routes: state => {
    let filteredRoutes = state.router.addedRoutes.filter((item, index, array) => item.path == "/home")
    for (const [idx, route] of filteredRoutes.entries()) {
      for (const [sub_idx, sub_route] of route.children.entries()) {
        for (const [sub_sub_idx, sub_sub_route] of sub_route.children.entries()) {
          // if (sub_sub_route.path.includes(":")) {
          //   filteredRoutes[idx].children[sub_idx].children.splice(sub_sub_idx, 1)
          // }
          if(sub_sub_route.path.includes('sf')) {
            filteredRoutes[idx].children[sub_idx].children.splice(sub_sub_idx, 1)
          }
        }
      }
    }

    for (const [idx, route] of filteredRoutes.entries()) {
      for (const [sub_idx, sub_route] of route.children.entries()) {
        for (const [sub_sub_idx, sub_sub_route] of sub_route.children.entries()) {
          if (sub_sub_route.path.includes("cs")) {
            filteredRoutes[idx].children[sub_idx].children.splice(sub_sub_idx, 1)
          }
        }
      }
    }

    for (const [idx, route] of filteredRoutes.entries()) {
      for (const [sub_idx, sub_route] of route.children.entries()) {
        for (const [sub_sub_idx, sub_sub_route] of sub_route.children.entries()) {
          if (sub_sub_route.path.includes("sf_m")) {
            filteredRoutes[idx].children[sub_idx].children.splice(sub_sub_idx, 1)
          }
        }
      }
    }

    for (const [idx, route] of filteredRoutes.entries()) {
      for (const [sub_idx, sub_route] of route.children.entries()) {
        for (const [sub_sub_idx, sub_sub_route] of sub_route.children.entries()) {
          if (sub_sub_route.path.includes(":")) {
            filteredRoutes[idx].children[sub_idx].children.splice(sub_sub_idx, 1)
          }
        }
      }
    }

    return filteredRoutes
  }
}
export default getters
