export default function isMobile(){
  let userAgentInfo = navigator.userAgent;
  let Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
  let flag = false;
  for(let v=0; v < Agents.length; v++){
    if(userAgentInfo.indexOf(Agents[v]) >= 0){
      flag = true;
      break;
    }
  }
  return flag;
}

export function isWeiXin () {
  var ua = window.navigator.userAgent.toLowerCase();
  // ua.indexOf('micromessenger')为真 - 微信端，如果为假，就是其他浏览器
  if (ua.indexOf('micromessenger') > -1) {
    return true; // 是微信端
  } else {
    return false;
  }
}
