import Cookies from 'js-cookie'

const TokenKey = 'token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  const expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + 2);
  return Cookies.set(TokenKey, token, {expires: expiryDate})
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
