export function processFetchedRoutes(routes) {

  let homeNumber = 0
  let homeNames = []
  let homeIdxes = []
  routes.forEach((item, index, array) => {
    if (item.path == '/home') {
      homeNumber += 1
      homeNames.push(item.name)
      homeIdxes.push(index)
    }
  })
  // 如果路由冲突了，在这里加判断修改【路由冲突】
  if (homeNumber == 2) {
    let routeIdx2Remove = homeIdxes[homeNames.indexOf("customerHome")]
    routes.splice(routeIdx2Remove, 1)
  }

  let demandIDNumber = 0
  let demandIDComponents = []
  let demandIDIdxes = []
  routes.forEach((item, index, array) => {
    if (item.path == '/demand/:id') {
      demandIDNumber += 1
      demandIDComponents.push(item.component)
      demandIDIdxes.push(index)
    }
  })
  if (demandIDNumber == 2) {
    let routeIdx2Remove = demandIDIdxes[demandIDComponents.indexOf("views/mobile/customerSite/demandDetails.vue")]
    routes.splice(routeIdx2Remove, 1)
  }

  routes.forEach(route => {
    const componentPath = route.component
    const meta = route.meta
    route.meta = eval("(" + meta + ")")
    route.component = () => import(`@/${componentPath}`)
    if (route.children) {
      processFetchedRoutes(route.children)
    }
  })
}
