import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element'
import './permission' // permission control
import vuetify from './plugins/vuetify'
// import videoPlayer from './plugins/videoPlayer.js'
import './plugins/maz-ui'
import './plugins/clipboard'

const DEV = process.env.NODE_ENV !== 'production'

if (!DEV) {
  Vue.config.productionTip = false
  Vue.config.devtools = false
} else {
  Vue.config.productionTip = true
  Vue.config.devtools = true
}



new Vue({
  router,
  store,
  vuetify,
  // VideoPlayer,
  render: h => h(App)
}).$mount('#app')

