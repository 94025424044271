import request from "@/network/utils/request"

export function loginRequest(data){
  return request({
      url:'/user/jwt-token-auth/',
      method:'post',
      data
  })
}


export function wxloginRequest(data){
  return request({
    url: '/user/wx-login/',
    method: 'post',
    data
  })
}

export function fetchRoutersRequest(data){
  return request({
    url: 'user/fetch-routers/',
    method: 'get',
    params: data
  })
}

export function fetchUserInfo() {
  return request({
    url: '/user/fetch-userinfo',
    method: 'get',
  })
}

export function fetchSTSTokenRequest(userId){
  return request({
    url: 'user/fetch-stsToken/',
    method: 'get',
    params: userId
  })
}

export function fetchStsTokenProject(userId){
  return request({
    url: 'user/fetch-stsToken-project/',
    method: 'get',
    params: userId
  })
}
export function fetchStsTokenProduct(userId){
  return request({
    url: 'user/fetch-stsToken-product/',
    method: 'get',
    params: userId
  })
}
export function fetchStsTokenFreelancerWork(userId){
  return request({
    url: 'user/fetch-stsToken-freelancer-work/',
    method: 'get',
    params: userId
  })
}