const routes = [
  {
    path: "/",
    name: "deflaut",
    meta: {title: '账号登录'},
    component: () => import('@/views/mobile/common/loginEntry.vue')
  },
  {
    path: "/index",
    name: "index",
    meta: {title: '账号登录'},
    component: () => import('@/views/mobile/common/loginEntry.vue')
  },
  {
    path: "/demandPublishEntry",
    name: "demand-publish-list",
    meta: {title: '需求发布入口'},
    component: () => import('@/views/mobile/common/demandPublishEntry.vue')
  },
  {
    path: "/product/:id",
    name: "product",
    meta: {title: '标准化产品'},
    component: () => import('@/views/mobile/common/product.vue')
  },
  {
    path: "/publishAndProduct",
    name: "publish-and-product",
    // meta: {title: '需求发布及标准化产品入口'},
    meta: {title: '首页'},
    component: () => import('@/views/mobile/common/publishAndProduct.vue')
  },
  {
    path: "/demandList",
    name: "demand-list",
    meta: {title: '需求列表'},
    component: () => import('@/views/mobile/common/demandList.vue')
  },
  {
    path: "/dawnswimPresent",
    name: "dawnswim-present",
    meta: {title: '平台资讯'},
    component: () => import('@/views/mobile/common/dawnswimPresent.vue')
  },
  {
    path: '/talentAndCase',
    name: 'talent-and-case',
    meta: {title: '人才/案例'},
    component: () => import('@/views/mobile/common/talentAndCase.vue')
  },
  {
    path: "/login-entry",
    name: "login-entry",
    meta: {title: '账号登录'},
    component: () => import('@/views/mobile/common/loginEntry.vue')
  },
  {
    path: "/login-pass",
    name: "login-pass",
    meta: {title: '账号密码登录'},
    component: () => import('@/views/mobile/common/loginPass.vue')
  },
  {
    path: "/free-to-ask",
    name: "free-to-ask",
    meta: {title: '免费需求咨询'},
    component: () => import('@/views/mobile/common/freeToAsk.vue')
  },
  {
    path: "/demandPayOrder",
    name: "fwh-pay-order",
    meta: {title: '订单支付'},
    component: () => import('@/views/mobile/common/demandPayOrder.vue')
  },
  {
    path: "/order-detail/:tradenum",
    name: "/order-detail",
    meta: {title: '订单详情'},
    component: () => import('@/views/mobile/common/orderDetail.vue')
  },
  {
    path: "/orderDetail/:tradenum",
    name: "/orderDetail",
    meta: {title: '订单详情'},
    component: () => import('@/views/mobile/adminSite/orderDetail.vue')
  },
  {
    path: "/myOrders",
    name: "/myOrders",
    meta: {title: '我的订单'},
    component: () => import('@/views/mobile/common/myOrders.vue')
  },
  {
    path: "/orders",
    name: "/orders",
    meta: {title: '订单管理'},
    component: () => import('@/views/mobile/adminSite/orders.vue')
  },
  {
    path: "/uncheckDemandModify/:id",
    name: "/uncheckDemandModify",
    meta: {title: '订单详情'},
    component: () => import('@/views/mobile/common//uncheckDemandModify.vue')
  },
  {
    path: "/productPayOrder",
    name: "productPayOrder",
    meta: {title: '订单支付'},
    component: () => import('@/views/mobile/common/productPayOrder.vue')
  },
  {
    path: "/chat",
    name: "chat",
    meta: {title: "ai语言模型"},
    component: () => import('@/views/mobile/common/chat.vue')
  },
  {
    path: "/historyChat",
    name: "historyChat",
    meta: {title: "历史对话"},
    component: () => import('@/views/mobile/common/historyChat.vue')
  },
  {
    path: "/demandAssistant",
    name: "demandAssistant",
    meta: {title: "需求助手"},
    component: () => import('@/views/mobile/common/demandAssistant.vue')
  },
  {
    path: "/assistantHistory",
    name: "assistantHistory",
    meta: {title: "需求助手历史对话"},
    component: () => import('@/views/mobile/common/assistantHistory.vue')
  },
]

export default routes