import axios from "axios";
import { Message } from "element-ui";
import { getToken } from "@/utils/token";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
});

//http request 拦截器
service.interceptors.request.use(
  config => {
    if (config.method === "get") {
      config.headers = {
        "Content-Type": "application/json",
        Authorization: 'JWT ' + getToken()
      };
    } else if (config.method === "post") {
      config.headers = {
        "Content-Type": "application/json",
        Authorization: 'JWT ' + getToken()
      };
    } else if (config.method === "delete") {
      config.headers = {
        "Content-Type": "application/json",
        Authorization: 'JWT ' + getToken()
      };
    } else if (config.method === "put") {
      config.headers = {
        "Content-Type": "application/json",
        Authorization: 'JWT ' + getToken()
      };
    }
    return config;
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
);

//http response 拦截器
service.interceptors.response.use(
  res => {
    if (res.data.status == 200 || res.status == 200) {
      return res.data;
    }else if(res.data.status == 500) {
      alert('500')
    } 
    else {
      Message({
        message: res.data.msg || "Error",
        type: "error",
        duration: 10 * 1000
      });
      return Promise.reject(new Error(res.message || "Error"));
    }
  },
  error => {
    console.log("err" + error); // for debug
    if(error.response.status == 500) {
      return Promise.reject(error)
    }
    Message({
      message: error.message,
      type: "error",
      duration: 10 * 1000
    });
    return Promise.reject(error);
  }
);

export default service;
